<template>
    <side-panel class="event-participants d-flex">
        <div class="position-sticky title d-flex pl-1 pr-3 pt-3 pb-2">
            <div class="mr-2">
                <router-link class="btn btn-link"
                             v-bind:to="`/activities/${ activityId }`">
                    <font-awesome-icon icon="arrow-left"></font-awesome-icon>
                </router-link>
            </div>
            <h3 class="font-weight-bolder flex-grow-1 mb-0">
                Participants of {{ event ? event.title : "" }}
            </h3>
        </div>
        <div class="flex-grow-1 overflow-auto p-2">
            <ul class="list-group">
                <ticket-item v-for="ticket in tickets"
                             v-bind:key="ticket._id"
                             v-bind:ticket="ticket">
                </ticket-item>
            </ul>
        </div>
    </side-panel>
</template>

<script>
import getTicketsByEventId from "../../api/activity/getTicketsByEventId";
export default {
    name: "EventParticipants",
    components: {
        SidePanel: () => import("../SidePanel"),
        TicketItem: () => import("./ActivityParticipants/TicketItem"),
    },
    props: {
        "activityId": {
            "type": String,
        },
    },
    data () {
        return {
            tickets: [],
        };
    },
    computed: {
        event () {
            return this.$store.getters["event/itemById"](this.activityId);
        },
    },
    methods: {

    },
    async mounted () {
        if (this.activityId) {
            try {
                this.tickets = await getTicketsByEventId(this.activityId);
            } catch (e) {
                console.error(e);
            }
            console.log(this.tickets);
        }
    },
    watch: {
        async "activityId" (newVal) {
            if (newVal) {
                try {
                    this.tickets = await getTicketsByEventId(newVal);
                } catch (e) {
                    console.error(e);
                }
                console.log(this.tickets);
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.event-participants {

}
</style>
